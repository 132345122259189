import Clickable from '/components/Clickable';
import PropTypes from 'prop-types';
import Typography from '/components/Typography';

import classNames from '/utils/class-names';

import { findProductTag } from '../../../utils/product-tags';
import { getCutsFromProductId } from '/services/subscriptions';

import {
	PRODUCT_TYPE_BIKINI,
	PRODUCT_TYPE_BOXERS,
	PRODUCT_TYPE_BOXERS_BRIEF,
	PRODUCT_TYPE_BOXERS_COOLING,
	PRODUCT_TYPE_BOXERS_COOLING_LONG,
	PRODUCT_TYPE_BOXERS_FLY,
	PRODUCT_TYPE_BOXERS_LONG_FLY,
	PRODUCT_TYPE_BOXERS_LOOSE,
	PRODUCT_TYPE_BOXERS_TRUNK,
	PRODUCT_TYPE_BOYSHORT,
	PRODUCT_TYPE_BOYS_UNDERWEAR,
	PRODUCT_TYPE_BRALETTES,
	PRODUCT_TYPE_CHEEKY,
	PRODUCT_TYPE_LAUNDRY_SHEETS,
	PRODUCT_TYPE_LONG_JOHNS,
	PRODUCT_TYPE_MENS_SHORTS,
	PRODUCT_TYPE_SOCKS_ANKLE,
	PRODUCT_TYPE_SOCKS_CREW,
	PRODUCT_TYPE_THONGS,
	PRODUCT_TYPE_THONGS_MODAL,
	PRODUCT_TYPE_WOMENS_BOXER,
} from '../../../services/static/product-types';

import { useEffect, useState } from 'react';

//Valid cuts for print/cut selector
const womensCuts = [
	PRODUCT_TYPE_BIKINI,
	PRODUCT_TYPE_BOYSHORT,
	PRODUCT_TYPE_BRALETTES,
	PRODUCT_TYPE_CHEEKY,
	PRODUCT_TYPE_THONGS,
	PRODUCT_TYPE_THONGS_MODAL,
	PRODUCT_TYPE_WOMENS_BOXER,
];

const mensCuts = [
	PRODUCT_TYPE_MENS_SHORTS,
	PRODUCT_TYPE_BOXERS,
	PRODUCT_TYPE_BOXERS_FLY,
	PRODUCT_TYPE_BOXERS_LONG_FLY,
	PRODUCT_TYPE_BOXERS_LOOSE,
	PRODUCT_TYPE_BOXERS_COOLING,
	PRODUCT_TYPE_BOXERS_BRIEF,
	PRODUCT_TYPE_BOXERS_TRUNK,
	PRODUCT_TYPE_SOCKS_ANKLE,
	PRODUCT_TYPE_SOCKS_CREW,
];

const renameByProductType = {
	'Athletic Shorts - 5 Inch': '5 Inch',
	'Athletic Shorts - 7 Inch': '7 Inch',
	'Athletic Shorts - 9 Inch': '9 Inch',
	[PRODUCT_TYPE_BOXERS]: 'No Fly',
	[PRODUCT_TYPE_BOXERS_BRIEF]: 'Briefs',
	[PRODUCT_TYPE_BOXERS_COOLING]: 'Cooling',
	[PRODUCT_TYPE_BOXERS_COOLING_LONG]: 'Cooling Long',
	[PRODUCT_TYPE_BOXERS_FLY]: 'w/ Fly',
	[PRODUCT_TYPE_BOXERS_LONG_FLY]: 'Long',
	[PRODUCT_TYPE_BOXERS_LOOSE]: 'Pouchless',
	[PRODUCT_TYPE_BOXERS_TRUNK]: 'Trunks',
	[PRODUCT_TYPE_BOYS_UNDERWEAR]: 'Boys',
	[PRODUCT_TYPE_SOCKS_ANKLE]: 'Ankle',
	[PRODUCT_TYPE_SOCKS_CREW]: 'Crew',
	[PRODUCT_TYPE_THONGS]: 'Seamless Thong',
	[PRODUCT_TYPE_THONGS_MODAL]: 'Modal Thong',
};

const renameByStyleNumber = {
	10290: 'Lace Cheeky',
	10281: 'Lace Modal Thong',
};

const renameByHandle = {
	'unscented-laundry-detergent-strips': 'Unscented',
	'boulder-breeze-eco-laundry-detergent-strips': 'Boulder Breeze',
};

const getDisplayTitle = (cut) => {
	const styleNumber = findProductTag(cut?.tags, 'style', 1);
	if (cut?.handle?.includes('lace')) {
		if (cut?.handle?.includes('cheeky')) {
			return 'Lace Cheeky';
		}
		return 'Lace Thong';
	}
	if (cut.handle.includes('5')) {
		return '5"';
	}
	if (cut.handle.includes('7')) {
		return '7"';
	}
	if (cut.handle.includes('9')) {
		return '9"';
	}
	return (
		renameByStyleNumber[styleNumber] ||
		renameByProductType[cut?.shopifyProductType] ||
		renameByHandle[cut?.handle] ||
		cut?.shopifyProductType
	);
};

const getNoun = (product) => {
	if (product.productType === "Men's Shorts") {
		return 'Inseam';
	}
	if (product.productType.includes('Laundry')) {
		return 'Scent';
	}
	return 'Cut';
};

const CutIcon = ({ cut, productHandle }) => {
	const displayTitle = getDisplayTitle(cut);

	return (
		<Clickable
			className={classNames(
				' rounded-[4px] text-center font-semibold px-2 uppercase tracking-wider h-[46px] basis-[calc(33%-.5rem)] leading-4 flex justify-center items-center',
				cut.handle === productHandle
					? 'bg-v2-brown text-v2-off-white border-v2-brown-darker border-2'
					: 'bg-v2-off-white text-v2-brown-darker border-v2-brown-darker border-2',
			)}
			linkToInternal={'/products/' + cut.handle}
			heapEventName="Cut Click">
			<Typography component="p" variant="heading-xs">
				{displayTitle}
			</Typography>
		</Clickable>
	);
};

const CutIconOnclick = ({ cut, productHandle, setActiveProductHandle }) => {
	const displayTitle = getDisplayTitle(cut);

	return (
		<Clickable
			className={classNames(
				' rounded-[4px] text-center font-semibold px-2 uppercase tracking-wider h-[46px] basis-[calc(33%-.5rem)] leading-4 flex justify-center items-center',
				cut.handle === productHandle ? 'bg-gray-800 text-white' : 'bg-gray-200',
			)}
			onClick={() => setActiveProductHandle(cut.handle)}>
			<Typography component="p" variant="body">
				{displayTitle}
			</Typography>
		</Clickable>
	);
};

const CutSelector = ({ product, setActiveProductHandle }) => {
	const [cuts, setCuts] = useState([]);

	useEffect(() => {
		async function getCuts() {
			const cutsResponse = await getCutsFromProductId(product.id);
			if (!cutsResponse || !Array.isArray(cutsResponse)) {
				setCuts([]);
				return;
			}

			setCuts(cutsResponse);
		}
		getCuts();
	}, [product]);

	if (!cuts || cuts.length === 0 || product.productType === "Men's T-Shirts") {
		return null;
	}

	let validCuts;

	if (womensCuts.includes(product.productType)) {
		validCuts = womensCuts;
	} else if (product?.productType?.includes('Sock')) {
		validCuts = [PRODUCT_TYPE_SOCKS_ANKLE, PRODUCT_TYPE_SOCKS_CREW];
	} else if (product?.productType === PRODUCT_TYPE_LAUNDRY_SHEETS) {
		validCuts = [PRODUCT_TYPE_LAUNDRY_SHEETS];
	} else if (mensCuts.includes(product.productType)) {
		validCuts = [
			"Men's Shorts",
			'Athletic Shorts - 5 Inch',
			'Athletic Shorts - 7 Inch',
			'Athletic Shorts - 9 Inch',
			PRODUCT_TYPE_BOYS_UNDERWEAR,
			PRODUCT_TYPE_BOXERS,
			PRODUCT_TYPE_BOXERS_FLY,
			PRODUCT_TYPE_BOXERS_LONG_FLY,
			PRODUCT_TYPE_BOXERS_LOOSE,
			PRODUCT_TYPE_BOXERS_COOLING,
			PRODUCT_TYPE_BOXERS_BRIEF,
			PRODUCT_TYPE_BOXERS_TRUNK,
			PRODUCT_TYPE_LONG_JOHNS,
		];
	}

	if (!validCuts) {
		return null;
	}

	return (
		<>
			<Typography component="label" variant="heading-xs">
				{getNoun(product)}
			</Typography>
			<div className="flex flex-wrap gap-2 mb-4 my-2">
				{cuts &&
					cuts.map((cut, i) => {
						if (validCuts.includes(cut.shopifyProductType) && cut.handle) {
							if (setActiveProductHandle) {
								return (
									<CutIconOnclick
										cut={cut}
										key={i}
										productHandle={product.handle}
										setActiveProductHandle={setActiveProductHandle}
									/>
								);
							}
							return <CutIcon cut={cut} key={i} productHandle={product.handle} />;
						}
					})}
			</div>
		</>
	);
};

CutIcon.propTypes = {
	cut: PropTypes.object,
	productHandle: PropTypes.string,
};

CutIconOnclick.propTypes = {
	cut: PropTypes.object,
	productHandle: PropTypes.string,
	setActiveProductHandle: PropTypes.func,
};

CutSelector.propTypes = {
	product: PropTypes.object,
	setActiveProductHandle: PropTypes.func,
};

module.exports = {
	CutSelector,
	mensCuts,
	womensCuts,
};
